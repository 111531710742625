<template>
  <div class="home">
    <br />
    <br />
    <div class="container">
      <div class="row justify-content-center">
        <div v-if="isLoading || isAuthLoading" class="text-center">
          データ読み込み中です。
          <img src="../assets/loader.gif" />
        </div>
        <div v-if="isAuthLoadingError">データを取得できませんでした。</div>
        <div v-else-if="!isQuery">ご指定のページが見つかりません。</div>
        <div v-else-if="!isLoading && !isAuthed">
          <form @submit.prevent="auth()">
            <div class="form-row form-inline">
              <div class="form-group">
                <div class="input-group">
                  <label for="password">パスワード：</label>
                  <input
                    type="password"
                    class="form-control"
                    id="password"
                    size="16"
                    placeholder=""
                    v-bind:readonly="readonly"
                    :value="authNum"
                    @input="inputPassword($event.target.value)"
                  />
                  <div class="input-group-append pointer">
                    <div class="input-group-text">
                      <i class="fas fa-search" @click="auth()"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div v-else>
          <form>
            <div class="form-row form-inline">
              <div class="form-group" v-if="type == 'container'">
                <div class="input-group">
                  <label for="containerNo">ｺﾝﾃﾅ№：</label>
                  <input
                    type="text"
                    class="form-control"
                    id="containerNo"
                    size="16"
                    placeholder=""
                    v-model="no"
                  />
                </div>
              </div>
            </div>
            <div class="form-row form-inline">
              <div class="form-group" v-if="type == 'container'">
                <div class="input-group">
                  <label for="containerNo">伝票№：</label>
                  <input
                    type="text"
                    class="form-control"
                    id="dispatchNo"
                    size="16"
                    placeholder=""
                    v-model="dispachNo"
                  />
                </div>
              </div>
              <div class="form-group" v-if="type == 'truck'">
                <div class="input-group">
                  <label for="truckNo">オーダー№：</label>
                  <input
                    type="text"
                    class="form-control"
                    id="truckNo"
                    size="16"
                    placeholder=""
                    v-bind:readonly="true"
                    v-model="no"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div v-if="isAuthed">
        <div v-if="!isInput || no == ''">№を入力してください。<br /></div>
        <div v-else>
          <div v-if="isInput && !executeSerach">該当のデータはありません。<br /></div>
          <div v-else-if="dataSet != null">
            <br />
            <b>追跡結果</b>
            <br />
            <div v-if="isQuery">納入日：{{ convertDateDisp(dispachDate) }}</div>
            <br />
            <div class="row justify-content-center" v-if="'pickupDate' in dataSet && dispachDate != ''">
              <form>
                <div class="form-row form-inline">
                  <div class="form-group">
                    <div class="input-group">
                      <label for="pickupDate">PickUp：</label>
                      <input
                        type="text"
                        class="form-control"
                        id="pickupDate"
                        placeholder=""
                        v-model="dataSet.pickupDate"
                      />
                      <div class="invalid-feedback">日時が無効です。</div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="row justify-content-center" v-if="dispachDate != ''">
              <form>
                <div class="form-row form-inline">
                  <div class="form-group">
                    <div class="input-group">
                      <label for="departureDate">&nbsp;&nbsp;出&nbsp;&nbsp;発：</label>
                      <input
                        type="text"
                        class="form-control"
                        id="departureDate"
                        placeholder=""
                        v-model="dataSet.departureDate"
                      />
                      <div class="invalid-feedback">日時が無効です。</div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="row justify-content-center" v-if="dispachDate != ''">
              <form>
                <div class="form-row form-inline">
                  <div class="form-group">
                    <div class="input-group">
                      <label for="arrivalDate">&nbsp;&nbsp;到&nbsp;&nbsp;着：</label>
                      <input
                        type="text"
                        class="form-control"
                        id="arrivalDate"
                        placeholder=""
                        v-model="dataSet.arrivalDate"
                      />
                      <div class="invalid-feedback">日時が無効です。</div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="row justify-content-center" v-if="dispachDate != ''">
              <form>
                <div class="form-row form-inline">
                  <div class="form-group">
                    <div class="input-group">
                      <label for="complationDate">&nbsp;&nbsp;完&nbsp;&nbsp;了：</label>
                      <input
                        type="text"
                        class="form-control"
                        id="complationDate"
                        placeholder=""
                        v-model="dataSet.complationDate"
                      />
                      <div class="invalid-feedback">日時が無効です。</div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <br />
            <div v-if="driverName != ''" class="text-center">
              ﾄﾞﾗｲﾊﾞｰ：{{ driverName }}
            </div>
            <div v-if="src_driverCd != ''" class="text-center">
              無線：{{ src_driverCd }}
            </div>
            <div v-if="driverNo != ''" class="text-center">車番：{{ driverNo }}</div>
            <div v-if="driverTel != ''" class="text-center">TEL：{{ driverTel }}</div>
            <br />
            <div class="row justify-content-center" v-if="dispachDate != ''">
              <div class="form-check form-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="clearmemo"
                  v-model="clearmemo"
                />
                <label class="form-check-label" for="clearmemo">
                  無線番号&nbsp;&nbsp;
                  <input
                    type="text"
                    class="form-control"
                    id="driver_cd"
                    size="5"
                    maxlength="3"
                    placeholder=""
                    v-model="driverCd"
                  />&nbsp;&nbsp; に変更する。
                </label>
              </div>
            </div>
            <br />
            <div class="row justify-content-center" v-show="dispachDate != '' && !isEdit">
              <form>
                <div class="form-row form-inline col-md-3">
                  <div class="form-group">
                    <div class="input-group">
                      <label for="auth-num">暗証番号：</label>
                      <input
                        type="password"
                        class="form-control"
                        id="auth-num"
                        size="8"
                        placeholder=""
                        v-model="authNum"
                      />
                      <div class="invalid-feedback">暗証番号が違います。</div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <br />
            <br />
            <div class="row justify-content-center" v-if="dispachDate != ''">
              <div>
                <button
                  type="button"
                  class="btn btn-primary btn-lg btn-block"
                  @click="sendData()"
                >
                  変更
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HttpClient from "../modules/http";
import { checkDateTime, checkDate } from "../modules/validation";

export default {
  name: "Home",
  components: {},
  data: function () {
    return {
      isLoading: false,
      isAuthLoading: true,
      isAuthed: false,
      isInput: true,
      isEdit: false,
      isQuery: false,
      authNum: "",
      passwd: "",
      executeSerach: false,
      dataSet: {
        truck: "",
        container: "",
        dispatchDate: "",
        pickupDate: "",
        departureDate: "",
        arrivalDate: "",
        complationDate: "",
        memo: "",
      },
      src_Dataset:{},
      driversData: {
        cd: "",
        no: "",
        name: "",
        tel: "",
        remarks: "",
      },
      data: null,
      no: "",
      src_no: "",
      httpClient: new HttpClient(),
      dispachDate: "",
      dispachNo: "",
      src_dispachNo:"",
      driverName: "",
      driverTel: "",
      driverNo: "",
      driverCd: "",
      src_driverCd: "",
      memo: "",
      order_no: "",
      clearmemo: false,
      type: "container",
    };
  },
  created: function () {
    if (this.$route.query != null && this.$route.query.qr != null) {
      let qr = this.$route.query.qr;
      let qr_array = qr.split("@");
      console.log(qr_array);
      if (qr_array.length < 2) {
        this.isLoading = false;
        this.isAuthLoading = false;
      } else {
        qr = qr_array[0];
        this.dispachNo = qr_array[1];
        this.src_dispachNo = qr_array[1];
        if (qr.length >= 19) {
          this.dispachDate = qr.slice(0, 8);
          this.no = qr.slice(8, this.$route.query.qr.length);
          this.src_no = qr.slice(8, this.$route.query.qr.length);
          this.authNum = this.$cookie.getCookie("authNum");
          if (this.authNum != "" && this.authNum != null) {
            this.isEdit = true;
          }
          this.getPasswdData();
          if (this.no.slice(0, 1) == "-") {
            this.type = "truck";
            this.isQuery = true;
            this.isInput = true;
            this.no = this.no.slice(1, this.no.length);
            this.src_no = this.no
            this.getTruckData();
          } else {
            this.type = "container";
            var alphaCheck = new RegExp(/^[A-Z]+$/);
            var numCheck = new RegExp(/^[0-9]+$/);
            const alpha = this.no.slice(0, 4);
            const num = this.no.slice(4, 7);

            if (
              alphaCheck.test(alpha) &&
              numCheck.test(num) &&
              checkDate(this.dispachDate)
            ) {
              this.isQuery = true;
              this.isInput = true;

              this.getContainerData();
              this.authNum = this.$cookie.getCookie("authNum");
              if (this.authNum != "" && this.authNum != null) {
                this.isEdit = true;
              }
              this.getPasswdData();
            } else {
              this.isLoading = false;
              this.isAuthLoading = false;
            }
          }
        } else {
          this.isLoading = false;
          this.isAuthLoading = false;
        }
      }
    } else {
      this.isLoading = false;
      this.isAuthLoading = false;
    }
  },
  methods: {
    loadingOff() {
      this.isLoading = false;
      this.isAuthLoading = false;
    },
    auth() {
      if (this.authNum == this.passwd) {
        this.isAuthed = true;
        this.$cookie.setCookie("authNum", this.passwd);
      } else {
        this.isAuthed = false;
      }
    },
    inputPassword(passwd) {
      this.authNum = passwd;
      this.auth();
    },
    getContainerData() {
      console.log("getContainerData");
      this.isLoading = true;
      const url = "containerTrace/container/get_container.php";
      const params = {
        con: this.no,
        day: this.dispachDate,
        dispatch_no: this.dispachNo
      };
      this.httpClient.get(url, params,  this.onSuccess, this.onError);
    },
    getTruckData() {
      console.log("getTruckData");
      this.isLoading = true;
      const url = "containerTrace/truck/get_truck.php";
      const params = {
        tr: this.no,
        day: this.dispachDate
      };
      this.httpClient.get(url, params, this.onSuccess, this.onError);
    },
    getPasswdData() {
      console.log("getPasswdData");
      this.isAuthLoading = true;
      const url = "energy/read.php";
      this.httpClient.getText(url, this.onSuccessPwd, this.onErrorPwd);
    },
    onSuccess(response) {
      console.log(response);
      this.loadingOff();
      this.dataSet = JSON.stringify(response.data);
      this.dataSet = JSON.parse(this.dataSet);
      this.src_dataSet = JSON.stringify(response.data);
      this.src_dataSet = JSON.parse(this.src_dataSet);
      console.log("**********")
      console.log(this.src_dataSet)
      console.log("**********")

      if (this.dataSet != null && this.dataSet != "") {
        this.dispachDate = this.dataSet.dispatchDate;
        this.executeSerach = true;

        if (this.dataSet.memo != "") {
          const driverInfo = this.dataSet.memo.split("@@");
          this.driverCd = driverInfo[0];
          this.src_driverCd = driverInfo[0];
          this.driverNo = driverInfo[1];
          this.driverName = driverInfo[2];
          this.driverTel = driverInfo[3];
          if (driverInfo.length > 4) {
            this.memo = driverInfo[4];
          }
          if (driverInfo.length > 5) {
            this.order_no = driverInfo[5];
          }
        } else {
          this.driverCd = "";
          this.driverNo = "";
          this.driverName = "";
          this.driverTel = "";
        }
      }
      this.$forceUpdate();
    },
    onEditSuccess() {
      this.loadingOff();
      const elementDivPickupDate = document.getElementById("pickupDate");
      const elementDivDepartureDate = document.getElementById("departureDate");
      const elementDivarrivalDate = document.getElementById("arrivalDate");
      const elementDivComplationDate = document.getElementById("complationDate");
      const elementDivAuthNum = document.getElementById("auth-num");
      if ("pickupDate" in this.dataSet && this.dataSet.pickupDate != "") {
        elementDivPickupDate.classList.remove("is-valid");
        elementDivPickupDate.classList.remove("is-invalid");
      }
      elementDivDepartureDate.classList.remove("is-valid");
      elementDivDepartureDate.classList.remove("is-invalid");
      elementDivarrivalDate.classList.remove("is-valid");
      elementDivarrivalDate.classList.remove("is-invalid");
      elementDivComplationDate.classList.remove("is-valid");
      elementDivComplationDate.classList.remove("is-invalid");
      elementDivAuthNum.classList.remove("is-valid");
      elementDivAuthNum.classList.remove("is-invalid");

      alert("更新しました。");

      this.src_no = this.no;
      this.src_dispachNo = this.dispachNo;

      console.log(this.type);

      let d1 = new Date();
      let breaker = true;
      while (breaker) {
        let d2 = new Date();
        if (d2 - d1 > 2000) {
          breaker = false;
          break;
        }
      }
      if (this.type == "container") {
        this.getContainerData();
      } else {
        this.getTruckData();
      }
    },
    onError() {
      this.loadingOff();
      alert("更新できません。");
    },
    onCheckDataError() {
      this.loadingOff();
      alert("更新できません。伝票Noが重複している可能性があります。");
    },
    onSuccessPwd(response) {
      this.loadingOff();
      let pwddata = JSON.stringify(response.data);
      pwddata = JSON.parse(pwddata);
      this.passwd = pwddata.energy;
      this.auth();
      this.$forceUpdate();
    },
    onErrorPwd() {
      this.loadingOff();
      this.isAuthLoadingError = true;
      this.passwd = "";
    },
    convertDateDisp(date) {
      return date.slice(0, 4) + "年" + date.slice(4, 6) + "月" + date.slice(6, 8) + "日";
    },
    convertDateFormat(strDate) {
      strDate = strDate.replaceAll("/", "");
      strDate = strDate.replaceAll("-", "");
      strDate = strDate.replaceAll(":", "");
      strDate = strDate.replaceAll(" ", "");

      return (
        strDate.slice(0, 4) +
        "/" +
        strDate.slice(4, 6) +
        "/" +
        strDate.slice(6, 8) +
        " " +
        strDate.slice(8, 10) +
        ":" +
        strDate.slice(10, 12) +
        ":" +
        strDate.slice(12, 14)
      );
    },
    sendData() {
      let validation = false;
      this.isEdit = true;
      const elementDivPickupDate = document.getElementById("pickupDate");
      const elementDivDepartureDate = document.getElementById("departureDate");
      const elementDivarrivalDate = document.getElementById("arrivalDate");
      const elementDivComplationDate = document.getElementById("complationDate");
      const elementDivAuthNum = document.getElementById("auth-num");

      if ("pickupDate" in this.dataSet && this.dataSet.pickupDate != "" && !checkDateTime(this.dataSet.pickupDate)) {
        validation = true;
        elementDivPickupDate.classList.remove("is-valid");
        elementDivPickupDate.classList.add("is-invalid");
      } else {
        if ("pickupDate" in this.dataSet && this.dataSet.pickupDate != "") {
          this.dataSet.pickupDate = this.convertDateFormat(this.dataSet.pickupDate);
        }
      }

      if (
        this.dataSet.departureDate != "" &&
        !checkDateTime(this.dataSet.departureDate)
      ) {
        validation = true;
        elementDivDepartureDate.classList.remove("is-valid");
        elementDivDepartureDate.classList.add("is-invalid");
      } else {
        if (this.dataSet.departureDate != "") {
          this.dataSet.departureDate = this.convertDateFormat(this.dataSet.departureDate);
        }
      }

      if (this.dataSet.arrivalDate != "" && !checkDateTime(this.dataSet.arrivalDate)) {
        validation = true;
        elementDivarrivalDate.classList.remove("is-valid");
        elementDivarrivalDate.classList.add("is-invalid");
      } else {
        if (this.dataSet.arrivalDate != "") {
          this.dataSet.arrivalDate = this.convertDateFormat(this.dataSet.arrivalDate);
        }
      }

      if (
        this.dataSet.complationDate != "" &&
        !checkDateTime(this.dataSet.complationDate)
      ) {
        validation = true;
        elementDivComplationDate.classList.remove("is-valid");
        elementDivComplationDate.classList.add("is-invalid");
      } else {
        if (this.dataSet.complationDate != "") {
          this.dataSet.complationDate = this.convertDateFormat(
            this.dataSet.complationDate
          );
        }
      }

      if (this.passwd != this.authNum) {
        validation = true;
        this.isEdit = false;
        elementDivAuthNum.classList.remove("is-valid");
        elementDivAuthNum.classList.add("is-invalid");
      } else {
        this.$cookie.setCookie("authNum", this.authNum);
        this.$cookie.setCookie("authNum2", "20201207");
        this.$cookie.setCookie("authNum3", "11111111");
      }
      if (!validation) {
        if (this.clearmemo && this.src_driverCd != this.driverCd) {
          if (this.driverCd == "") {
            this.driversData.cd = "";
            this.driversData.no = "";
            this.driversData.name = "";
            this.driversData.tel = "";
            this.driversData.remarks = "";

            this.dataSet.memo = this.buildMemo();
            this.executeStamp();
          } else {
            this.isLoading = true;
            const url = "containerTrace/driver/get_driver.php";
            const params = {
              cd: this.driverCd
            };
            this.httpClient.get(url, params, this.onSuccessDriver, this.onErrorDriver);
          }
        } else {
          this.executeStamp();
        }
      }
    },
    onCheckData(response) {
      this.loadingOff();
      let checkDataSet = JSON.stringify(response.data);
      checkDataSet = JSON.parse(checkDataSet);
      if (checkDataSet != null && checkDataSet != "") {
        if (
          checkDataSet.pickupDate == "" &&
          checkDataSet.departureDate == "" &&
          checkDataSet.arrivalDate == "" &&
          checkDataSet.complationDate == ""
        ) {
          let url = ""
          if (this.type == "truck") {
            url = "containerTrace/truck/set_truck.php";
          }else{
            url = "containerTrace/container/stamp.php";
            console.log("**********")
            console.log(this.src_dataSet)
            console.log("**********")
            this.dataSet["src"] = this.src_dataSet;
          }
          this.dataSet.container = this.no;
          this.dataSet["dispatchNo"] = this.dispachNo;
          this.httpClient.edit(url, this.dataSet, this.onEditSuccess, this.onError);
        } else {
          if(confirm("対象の伝票番号には、既に別のコンテナ情報が紐づけられています。\n現在の内容で上書きしますか？")){
            let url = ""
            if (this.type == "truck") {
              url = "containerTrace/truck/set_truck.php";
            }else{
              url = "containerTrace/container/stamp.php";
              console.log("**********")
              console.log(this.src_dataSet)
              console.log(this.dataSet)
              console.log("**********")
              this.dataSet["src"] = this.src_dataSet;
            }
            this.dataSet.container = this.no;
            this.dataSet["dispatchNo"] = this.dispachNo;
            this.httpClient.edit(url, this.dataSet, this.onEditSuccess, this.onError);
          }else{
            this.no = this.src_no;
          }
        }
      }
      this.$forceUpdate();
    },
    executeStamp() {
      this.isLoading = true;
      console.log(this.dataSet);

      this.dataSet["dispatchNo"] = this.dispachNo;

      if (this.src_no == this.no && this.src_dispachNo == this.dispachNo) {
        let url = "containerTrace/container/stamp.php";
        if (this.type == "truck") {
          url = "containerTrace/truck/set_truck.php";
        }else{
          this.dataSet["departureMemo"] = this.dataSet.memo;
          this.dataSet["arrivalMemo"] = this.dataSet.memo;
          this.dataSet["complationMemo"] = this.dataSet.memo;
        }
        this.httpClient.edit(url, this.dataSet, this.onEditSuccess, this.onError);
      } else {
        this.isLoading = true;

        let url = "containerTrace/container/get_container.php";
        let params = {
          con: this.no,
          day: this.dispachDate,
          dispatch_no: this.dispachNo
        };
        if (this.type == "truck") {
          url = "containerTrace/truck/get_truck.php";
          params = {
            tr: this.no,
            day: this.dispachDate
          };
        }
        this.httpClient.get(url, params, this.onCheckData, this.onCheckDataError);
      }
    },
    onSuccessDriver(response) {
      this.loadingOff();
      this.driversData = JSON.stringify(response.data);
      this.driversData = JSON.parse(this.driversData);
      if (this.driversData.no == "") {
        alert("ドライバー情報がありません。");
      } else {
        this.dataSet.memo = this.buildMemo();
        this.executeStamp();
      }
      this.$forceUpdate();
    },
    onErrorDriver() {
      alert("無線番号に対するドライバー情報が見つかりません。");
    },
    buildMemo() {
      return (
        this.driversData.cd +
        "@@" +
        this.driversData.no +
        "@@" +
        this.driversData.name +
        "@@" +
        this.driversData.tel +
        "@@" +
        this.driversData.remarks +
        "@@" +
        this.order_no
      );
    },
  },
};
</script>
