<template>
  <div class="home">
    <br />
    <br />
    <div class="container">
      <div class="row justify-content-center">
        <div v-show="isLoading || isPwLoading" class="text-center">
          データ読み込み中です。
          <img src="../assets/loader.gif" />
        </div>
        <div v-show="!isLoading && !isAuthed">
          <form @submit.prevent="auth()">
            <div class="form-row form-inline">
              <div class="form-group">
                <div class="input-group">
                  <label for="password">パスワード：</label>
                  <input
                    type="password"
                    class="form-control"
                    id="password"
                    size="16"
                    placeholder=""
                    v-bind:readonly="readonly"
                    :value="authNum"
                    @input="inputPassword($event.target.value)"
                  />
                  <div class="input-group-append pointer">
                    <div class="input-group-text">
                      <i class="fas fa-search" @click="auth()"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div v-show="!isLoading && isAuthed">
          <form @submit.prevent="search()">
            <div class="form-row form-inline">
              <div class="form-group">
                <div class="input-group">
                  <label for="orderNo">コンテナNo：</label>
                  <input
                    type="text"
                    class="form-control"
                    id="orderNo"
                    size="8"
                    placeholder=""
                    v-bind:readonly="readonly"
                  />
                  <div class="input-group-append pointer" id="OrderNo2">
                    <div class="input-group-text" @click="search()">
                      <i class="fas fa-search"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div v-if="isAuthed && orderNo == ''">コンテナNoを入力してください。<br /></div>
      <div v-else-if="message != ''" v-html="message"></div>
      <div v-else>
        <div v-if="(containerDataSet == null || containerDataSet.length == 0) && isSearch">
          該当のデータはありません。<br />
        </div>
        <div v-else-if="containerDataSet != null && containerDataSet.length > 0">
          <br />
          <b>{{ orderNo }}の検索結果</b>
          <br />
          <table
            class="table table-striped table-hover table-bordered table-responsive-md table-sm row-scrollable"
            id="table"
          >
            <thead>
              <tr>
                <th class="align-middle text-center pointer" scope="col" @click="sortList('driver', String)">ドライバー情報&nbsp;&nbsp;{{dispSortMark('driver')}}</th>
                <th class="align-middle text-center pointer" scope="col" @click="sortList('container', String)">コンテナNo&nbsp;&nbsp;{{dispSortMark('container')}}</th>
                <th class="align-middle text-center pointer" scope="col" @click="sortList('dispatchNo', String)">伝票No&nbsp;&nbsp;{{dispSortMark('dispatchNo')}}</th>
                <th class="align-middle text-center pointer" scope="col" @click="sortList('history', String)">履歴&nbsp;&nbsp;{{dispSortMark('history')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, idx) in containerDataSet" :key="idx" @click="toEdit(data)">
                <td>
                  {{ dispDriverInfo(data) }}
                </td>
                <td>
                  {{ dispContainerInfo(data) }}
                </td>
                <td>
                  {{ dispDispatchInfo(data) }}
                </td>
                <td>
                  {{ dispHistory(data) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HttpClient from "../modules/http";

export default {
  name: "AdminList",
  components: {},
  data: function () {
    return {
      isLoading: false,
      isPwLoading: true,
      isAuthed: false,
      isSearch: false,
      authNum: "",
      passwd: "",
      type:"container",
      executeSerach: false,
      containerDataSet: null,
      containerData: {
        container: "",
        dispatchDate: "",
        pickupDate: "",
        departureDate: "",
        arrivalDate: "",
        complationDate: "",
        memo: "",
      },
      orderNo: "",
      message:"",
      httpClient: new HttpClient(),
      orderData: {
        isOrder: true,
        orderColumn: "order",
        isAsc: true,
        orderFunction: String,
      },
    };
  },
  created: function () {
    this.authNum = this.$cookie.getCookie("authNum");
    this.getPasswdData();
  },
  mounted:function() {
  },
  methods: {
    loadingOff(){
      this.isLoading = false;
      this.isPwLoading = false;
    },
    auth() {
      if (this.authNum == this.passwd) {
        this.isAuthed = true;
        this.$cookie.setCookie("authNum", this.passwd);
      } else {
        this.isAuthed = false;
      }
    },
    search() {
      this.orderNo = document.getElementById('orderNo').value;
      if(this.orderNo != ""){
        this.message = ""
        this.getContainerData();
      }else{
        this.message = "コンテナNoを入力してください。"
      }
    },
    inputPassword(passwd){
      this.authNum = passwd
      this.auth()
    },
    sortList(name, func, asc) {
      if (asc == undefined || asc == null) {
        this.orderData.isAsc = !this.orderData.isAsc;
      } else {
        this.orderData.isAsc = asc;
      }

      this.orderData.orderColumn = name;
      this.orderData.isOrder = true;
      this.orderData.orderFunction = func;
      this.containerDataSet = this.containerDataSet.sort((a, b) => {
        if(name == "driver"){
          a = this.dispDriverInfo(a) + this.dispHistory(a);
          b = this.dispDriverInfo(b) + this.dispHistory(b);
        }else if(name == "container"){
          a = this.dispContainerInfo(a);
          b = this.dispContainerInfo(b);
        }else{
          a = this.dispHistory(a);
          b = this.dispHistory(b);
        }
        return (
          (func(a) === func(b) ? 0 : func(a) > func(b) ? 1 : -1) *
          (this.orderData.isAsc ? 1 : -1)
        );
      });
    },
    dispDriverInfo(data) {
      if (data.memo != "") {
        const driverInfo = data.memo.split("@@");
        if (driverInfo.length >= 5) {
          return driverInfo[0] + " " + driverInfo[2];
        }
      }
      return "";
    },
    dispContainerInfo(data) {
      if (data.container != "") {
        return data.container;
      }
      return "";
    },
    dispDispatchInfo(data){
      if (data.dispatchNo != "") {
        return data.dispatchNo;
      }
      return "";
    },
    dispHistory(data) {
      let time = ""
      if (data.pickupDate != "") {
        time = data.pickupDate + " PickUp"
      }

      if (data.departureDate != "") {
        time = data.departureDate + " 出発"
      }

      if (data.arrivalDate != "") {
        time = data.arrivalDate + " 到着"
      }

      if (data.complationDate != "") {
        time = data.complationDate + " 完了"
      }

      return time
    },
    dispSortMark(name){
      let ret = ""
      if(this.orderData.orderColumn == name){
        if(this.orderData.isAsc){
          ret = "▲"
        }else{
          ret = "▼"
        }
      }
      return ret;
    },
    getContainerData() {
      this.isLoading = true;
      const url = "containerTrace/container/containerlist.php";
      const params = {
        con: this.orderNo
      };
      this.httpClient.get(url, params, this.onSuccess, this.onError);
    },
    getPasswdData() {
      this.isPwLoading = true;
      const url = "energy/read.php";
      this.httpClient.getText(url, this.onSuccessPwd, this.onErrorPwd);
    },
    onSuccess(response) {
      this.isSearch = true;
      this.loadingOff();
      let content_buff = JSON.stringify(response.data);
      content_buff = JSON.parse(content_buff);
      console.log(content_buff);

      this.containerDataSet = content_buff.traceList;

      this.sortList('history', String,false);

      this.$forceUpdate();
    },
    onError(response) {
      this.containerDataSet = "";
      this.isSearch = true;
      this.loadingOff();
      console.log(response)
    },
    onSuccessPwd(response) {
      this.loadingOff();
      let pwddata = JSON.stringify(response.data);
      pwddata = JSON.parse(pwddata);
      this.passwd = pwddata.energy;

      if (this.authNum != "") {
        this.auth();
      }
      this.$forceUpdate();
    },
    onErrorPwd() {
      this.loadingOff();
      this.isAuthLoadingError = true;
      this.passwd = "";
    },
    toEdit(data){
      let path = "/a/"
      let routeData = "";
      if(this.type=="container"){
        routeData = this.$router.resolve({path: path,query: {qr: data.dispatchDate + data.container+"@"+data.dispatchNo}});
      }else{
        routeData = this.$router.resolve({path: path,query: {qr: data.dispatchDate + "-" + data.truck + "@"}});
      }
      
      window.open(routeData.href, '_blank');
    },
    convertDateFormat(strDate) {
      strDate = strDate.replaceAll("/", "");
      strDate = strDate.replaceAll("-", "");
      strDate = strDate.replaceAll(":", "");
      strDate = strDate.replaceAll(" ", "");

      return (
        strDate.slice(0, 4) +
        "/" +
        strDate.slice(4, 6) +
        "/" +
        strDate.slice(6, 8)
      );
    },
  },
};
</script>
